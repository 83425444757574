import "../styles/nprogress.css";
import '@mantine/notifications/styles.css';
import '@mantine/core/styles.css';
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Script from 'next/script';
import HeadSeo from "../components/head/HeadSeo";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import { PrivyProvider } from "@privy-io/react-auth";
import { PrivyLoader } from "../components/loaders/PrivyLoader";
import Web3Provider from "../providers/Web3Provider";
import LoadingOverlay from "../components/LoadingOverlay";
import { Notifications } from '@mantine/notifications';
import {createTheme, MantineProvider, Button, rem } from '@mantine/core';
import { defaultMetaTags, PRIVY_APP_ID, BASE_URL, SUPPORTED_CHAINS, DEFAULT_CHAIN } from "../config";


const DefaultLayout = dynamic(() => import("../layouts"), {
    ssr: false,
    loading: () => <LoadingOverlay/>,
});

export default function CachingApp({ Component, pageProps }: any) {
    const [queryClient] = useState(new QueryClient());
    const router = useRouter();

    useEffect(() => {
        const handleStart = () => {
            NProgress.start();
        };
        const handleStop = () => {
            NProgress.done();
        };

        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleStop);
        router.events.on("routeChangeError", handleStop);

        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleStop);
            router.events.off("routeChangeError", handleStop);
        };
    }, [router]);

    const getLayout =
        Component.getLayout ||
        ((page: React.ReactNode) => (
            <DefaultLayout>
                {page}
            </DefaultLayout>
        ));

    const metaTags = { ...defaultMetaTags, ...pageProps.metaTags };

    const theme = createTheme({
        components: {
          Button: Button.extend({
            vars: (theme, props) => {
              if (!props.variant) {
                return {
                  root: {
                    '--button-bg': 'teal',
                  },
                };
              }

              if (props.variant === 'transparent') {
                return {
                  root: {
                    '--button-color': 'teal',
                  },
                };
              }
      
              return { root: {} };
            },
          }),
        },
      });
         

    return (
        <>
            <MantineProvider theme={theme} forceColorScheme='dark'>
                <Notifications />
                <Script src="https://telegram.org/js/telegram-web-app.js"></Script>
                <HeadSeo baseURL={BASE_URL} {...metaTags} />
                <QueryClientProvider client={queryClient}>
                    {/* TODO: Move constants to config file */}
                    <PrivyProvider
                        appId={PRIVY_APP_ID || ''}
                        config={{
                            appearance: {
                                theme: 'dark',
                                accentColor: '#009688',
                                logo: BASE_URL + '/logo.png'
                            },
                            embeddedWallets: {
                                createOnLogin: 'users-without-wallets',
                                showWalletUIs: false,
                            },
                            supportedChains: SUPPORTED_CHAINS,
                            defaultChain: DEFAULT_CHAIN,
                        }}>
                        <PrivyLoader>
                            <Web3Provider>
                                {getLayout(<Component {...pageProps} />)}
                            </Web3Provider>
                        </PrivyLoader>
                    </PrivyProvider>
                </QueryClientProvider>
            </MantineProvider>
        </>
    );
}
