import { usePrivy } from '@privy-io/react-auth';
import LoadingOverlay from '../LoadingOverlay';
export const PrivyLoader = ({ children }: { children: any }) => {
    const { ready } = usePrivy();

    if (!ready) { 
        return <LoadingOverlay />
    }
    else {
        return children;
    }
};
