import { SiteMap } from "./siteMap";

export * from "./web3";
export * from "./siteMap";
export * from "./telegram";

export interface WebSiteConfig {
    SITEMAP: SiteMap;
    DEFAULT_LOGO: string;
    COPYRIGHT_NAME: string;
    DISCORD?: string;
    TWITTER?: string;
    GITHUB?: string;
}

export const CLIENT_ENV = {
    web: "web",
    telegramMiniApp: "telegramMiniApp",
};