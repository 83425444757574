import { createContext } from "react";
import { Web3ProviderInterface } from "../../types";

export const Web3ProviderContext = createContext<Web3ProviderInterface>({
    changeChain: () => console.error("not initialized"),
    getChainFromId: () => console.error("not initialized"),
    logout: () => console.error("not initialized"),
});

export default Web3ProviderContext;
