import { ethers } from "ethers";
import { useEffect, useReducer } from "react";
import { signAccessToken, tryGetAuthenticated } from "@peersky/eth-auth";
import { JsonRpcSigner } from "@ethersproject/providers";

type State = {
    token: string | null;
    isValid: boolean;
    isPending: boolean;
};

type Action =
    | { type: "SET_TOKEN"; payload: string | null }
    | { type: "SET_IS_VALID"; payload: boolean }
    | { type: "SET_PENDING"; payload: boolean }
    | { type: "DELETE_TOKEN"; payload: null };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "SET_TOKEN":
            return { ...state, token: action.payload };
        case "SET_IS_VALID":
            return { ...state, isValid: action.payload };
        case "SET_PENDING":
            return { ...state, isPending: action.payload };
        case "DELETE_TOKEN":
            return { isValid: false, isPending: false, token: null };
        default:
            return state;
    }
}
export function useEEAToken({
    domain,
    provider,
    timeout = 60 * 60 * 24 * 30,
    account,
}: {
    domain: any;
    provider: ethers.providers.Web3Provider;
    timeout?: number;
    account: string;
}) {
    const isEEATokenValid = (token: string) => {
        try {
            const isValid =
                ethers.utils.getAddress(account) ===
                tryGetAuthenticated(token, domain);
            return isValid;
        } catch (e) {}
        return false;
    };

    const [state, dispatch] = useReducer(reducer, {
        token: null,
        isValid: false,
        isPending: false,
    });

    const deleteToken = () => {
        sessionStorage.removeItem("EEAToken");
        localStorage.removeItem(`EEAToken-${account}`);
        dispatch({ type: "DELETE_TOKEN", payload: null });
    };

    const signAppToken = async (signer: JsonRpcSigner) => {
        return signAccessToken(signer, timeout, domain);
    };

    const requestEEAToken = async () => {
        if (!state.isPending && provider.getSigner()) {
            dispatch({ type: "SET_PENDING", payload: true });
            if (provider.getSigner())
                signAppToken(provider.getSigner()).then(
                    (token) => {
                        sessionStorage.setItem("EEAToken", token);
                        localStorage.setItem(`EEAToken-${account}`, token);
                        dispatch({ type: "SET_IS_VALID", payload: true });
                        dispatch({ type: "SET_PENDING", payload: false });
                    },
                    (e) => {
                        console.error("EEA Token error", e);
                        dispatch({ type: "SET_IS_VALID", payload: false });
                        dispatch({ type: "SET_PENDING", payload: false });
                    }
                );
        }
    };

    const ensureEEAToken = async () => {
        const tryCurrentToken = sessionStorage.getItem(`EEAToken`);
        if (!tryCurrentToken || !isEEATokenValid(tryCurrentToken)) {
            const tryGetFromLocalStore = localStorage.getItem(
                `EEAToken-${account}`
            );
            if (
                !tryGetFromLocalStore ||
                !isEEATokenValid(tryGetFromLocalStore)
            ) {
                ethers.utils.isAddress(account)
                    ? requestEEAToken()
                    : dispatch({ type: "SET_IS_VALID", payload: false });
            } else {
                sessionStorage.setItem("EEAToken", tryGetFromLocalStore);
                dispatch({ type: "SET_IS_VALID", payload: true });
            }
        } else {
            dispatch({ type: "SET_IS_VALID", payload: true });
        }
    };

    useEffect(() => {
        if (account) {
            console.log(
                "account change detected -> updating eea token",
                account
            );
            dispatch({ type: "SET_IS_VALID", payload: false });
            ensureEEAToken();
        }
    }, [account]);

    return {
        ...state,
        deleteToken,
        ensureEEAToken,
    };
}
