import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { ConnectedWallet, usePrivy, useWallets } from "@privy-io/react-auth";
import { Web3ProviderContext } from "./context";
import { SUPPORTED_CHAINS } from "../../config";
import { Chain } from 'viem';
import { WalletState, WALLET_STATES } from "../../types";
import { useEEAToken } from "./useEEAToken";
import { DOMAIN_NAME } from "../../config";

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
    const { user, logout, authenticated } = usePrivy();
    const [web3Provider, setWeb3Provider] = useState<ethers.providers.Web3Provider | null>(null);
    const [account, setAccount] = useState<string>("");
    const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>();
    const [currentChain, setCurrentChain] = useState<Chain>();
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const { wallets }: { wallets: ConnectedWallet[] } = useWallets();
    const [connectionState, setConnectionState] = React.useState<WalletState>(WALLET_STATES.CONNECTING);
    const domain = { name: DOMAIN_NAME.toLowerCase(), version: "0.1" };
    const eeaToken: ReturnType<typeof useEEAToken> = useEEAToken({ domain, provider: web3Provider, account: account });

    useEffect(() => {
        const fetchWeb3Provider = async () => {
            if (authenticated && user && wallets.length > 0) {
                try {
                    const wallet: ConnectedWallet = wallets[0];
                    const ethereumProvider: any = await wallet.getEthereumProvider();
                    const web3Provider = new ethers.providers.Web3Provider(ethereumProvider);
                    setWeb3Provider(new ethers.providers.Web3Provider(ethereumProvider));
                    const signer = web3Provider.getSigner();
                    setSigner(signer);
                    await signer.getAddress().then((address: string) => {
                        setAccount(address);
                    });
                    await web3Provider.getNetwork().then((network: ethers.providers.Network) => {
                        const currentChain = SUPPORTED_CHAINS.find((chain) => chain.id === network.chainId);
                        setCurrentChain(currentChain);
                    });
                    setIsConnected(true);
                } catch (error) {
                    console.error("Error fetching web3 provider: " + error);
                }
            } else {
                setWeb3Provider(null);
                setAccount("");
                setCurrentChain(null);
                setIsConnected(false);
            }
        };

        fetchWeb3Provider();
    }, [wallets]);

    React.useLayoutEffect(() => {
        let newConnectionState: WalletState = WALLET_STATES.CONNECTING;

        const validateChainAndSetConnectionState = async () => {
            let blockNumber = 0;
            try {
                blockNumber = await web3Provider.getBlockNumber();
            } catch (e) {
                newConnectionState = WALLET_STATES.BLOCK_ERROR;
                console.warn("Block number could not be fetched");
            }
            if (isValidBlockNumber(blockNumber)) {
                newConnectionState = isKnownChainId(currentChain.id) ? WALLET_STATES.CONNECTED : WALLET_STATES.UNKNOWN_CHAIN;
            } else {
                newConnectionState = WALLET_STATES.BLOCK_ERROR;
            }

            setConnectionState(newConnectionState);
            console.log("Setting connection state to: " + newConnectionState);
        };

        if (isConnected && currentChain) {
            validateChainAndSetConnectionState();
        } else {
            if(newConnectionState !== connectionState) {
                setConnectionState(newConnectionState);
                console.log("Setting connection state to: ", connectionState);
            }
        }

        const isValidBlockNumber = (blockNumber: number): boolean => {
            return !!blockNumber && blockNumber !== 0;
        };
        
        const isKnownChainId = (chainId: number): boolean => {
            return SUPPORTED_CHAINS.some((chain) => chain.id === chainId);
        };
    }, [
        isConnected,
        Web3Provider,
        currentChain,
        setConnectionState,
        account
    ]);

    const changeChain = async (wallet: ConnectedWallet, chain: Chain) => {
        try {
            await wallet.switchChain(chain.id);
        } catch (switchError) {
            console.error(switchError);
        }
    };

    return (
        <Web3ProviderContext.Provider
            value={{
            web3Provider: web3Provider,
            currentChain: currentChain,
            signer: signer,
            account: account,
            isConnected: isConnected,
            connectionState: connectionState,
            eeaPending: eeaToken.isPending,
            ensureEEAToken: eeaToken.ensureEEAToken,
            changeChain: changeChain,
            logout: () => {
                eeaToken.deleteToken();
                logout();
            },
            }}
        >
            {children}
        </Web3ProviderContext.Provider>
    );
};

export default Web3Provider;