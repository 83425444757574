import { LoadingOverlay } from "@mantine/core";

const LoadingOveraly = () => {
    return (
        <LoadingOverlay visible={true} zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2, backgroundOpacity: 0 }}
            loaderProps={{ color: 'teal.9', type: 'bars' }} 
        />
    )
};

export default LoadingOveraly;