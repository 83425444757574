import { ethers } from "ethers";
import { JsonFragment } from "@ethersproject/abi";
import { Chain } from "viem";

export interface Web3ProviderInterface {
    web3Provider: ethers.providers.Web3Provider | undefined;
    currentChain: Chain | undefined;
    signer: ethers.providers.JsonRpcSigner | undefined;
    account: string | undefined;
    connectionState: WalletState;
    isConnected: boolean;
    eeaPending: boolean;
    changeChain: typeof ChangeChain;
    logout: Function;
    ensureEEAToken: Function;
}

export type WalletState =
    | "DISCONNECTED"
    | "CONNECTING"
    | "CONNECTED"
    | "UNKNOWN_CHAIN"
    | "BLOCK_ERROR";

export const WALLET_STATES: { [key in WalletState]: WalletState } = {
    DISCONNECTED: "DISCONNECTED",
    CONNECTING: "CONNECTING",
    CONNECTED: "CONNECTED",
    UNKNOWN_CHAIN: "UNKNOWN_CHAIN",
    BLOCK_ERROR: "BLOCK_ERROR",
};

//TODO probably should be moved to a different file
export type PrivyAccountType = "wallet" | "google_oauth" | "discord_oauth" | "farcaster" | "telegram";

export declare function ChangeChain(chainId: number): void;